<!--
 * @Description: 指派/更换工程师弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-30 09:11:35
 * @LastEditTime: 2023-03-20 15:53:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible="isChange"
      width="800px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      v-loading="dialogLoading"
      :before-close="handleClose"
      custom-class="createDispatchDialog"
    >
      <div class="dialog-title">
        <span v-if="!hiddenTip"
          >已选中{{ itemList.length ? itemList.length : 0 }}个安装任务</span
        >
        <span v-if="!hiddenTip">(忽略{{ ignoreNum }}个不可操作任务项)</span>
      </div>
      <el-form
        label-width="120px"
        ref="changeForm"
        :model="changeForm"
        :rules="changeFormRules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="工程师" prop="engineerrpid">
              <e6-vr-select
                v-model="changeForm.engineerrpid"
                :data="engineerList"
                placeholder="工程师"
                title="工程师"
                :props="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
                clearable
                multiple
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否需要上门" prop="isNeedVisit">
              <e6-vr-select
                v-model="changeForm.isNeedVisit"
                :data="isCarryEquList"
                placeholder="是否需要上门"
                title="是否需要上门"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户联系人" prop="corpContactName">
              <el-input
                placeholder="客户联系人"
                v-model="changeForm.corpContactName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户联系电话" prop="corpContactPhone">
              <el-input
                placeholder="客户联系电话"
                v-model="changeForm.corpContactPhone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="basicInfo.taskSource == 3 || basicInfo.taskSource == 5"
              label="实施对接人"
              prop="contactName"
              :rules="{
                required: true,
                message: '请输入实施对接人',
                trigger: ['blur', 'change']
              }"
            >
              <el-input
                aria-placeholder="实施对接人"
                v-model="changeForm.contactName"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-else
              label="实施对接人"
              prop="contactId"
              :rules="{
                required: true,
                message: '请选择实施对接人',
                trigger: ['blur', 'change']
              }"
            >
              <div class="select-content">
                <e6-vr-select
                  style="width:200px"
                  v-model="changeForm.contactId"
                  :data="contactList"
                  :popper-append-to-body="false"
                  placeholder="实施对接人"
                  title="实施对接人"
                  :props="{
                    id: 'contactId',
                    label: 'contactName'
                  }"
                  @change="handleContact"
                ></e6-vr-select>
                <el-button
                  type="text"
                  style="margin-left: 10px"
                  @click="addConcat"
                >
                  添加实施对接人
                </el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实施对接电话" prop="contactPhone">
              <el-input
                aria-placeholder="实施对接电话"
                v-model="changeForm.contactPhone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="省市区地址" prop="defaultCity">
              <e6-vr-select
                style="width:300px"
                v-model="changeForm.defaultCity"
                :data="addressList"
                placeholder="省市区地址"
                title="省市区地址"
                virtual
                clearable
                :is-title="true"
                remote
                :popper-append-to-body="false"
                @filterChange="handleLoadAddressFilter"
                @change="handleCitySelect"
                :props="{
                  id: 'fullName',
                  label: 'fullName'
                }"
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址" prop="insAddress">
              <el-input
                placeholder="详细地址"
                v-model="changeForm.insAddress"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="约定时间" prop="arrangedate">
              <el-date-picker
                v-model="changeForm.arrangedate"
                type="datetime"
                placeholder="选择日期"
                value-format="timestamp"
                popper-class="special"
                :picker-options="pickerOptions1"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="指派备注" prop="remark">
              <el-input
                placeholder="指派备注"
                v-model="changeForm.remark"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实施原因" prop="implementationReason">
              <el-input
                placeholder="实施原因"
                v-model="changeForm.implementationReason"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加联系人弹框 -->
    <add-concat-dialog
      :addContactVisible="addContactVisible"
      :corpId="customerId"
      :corpName="basicInfo.customerName"
      @handleClose="closeDialog"
      @updateList="getContactList"
      :isNeedReq="true"
    ></add-concat-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { telAndMobileValid } from "@/utils/validate";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import {
  assignEngineer,
  changeEngineer,
  getContactList,
  getEngineer,
  getAddressData
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "changeEngineer",
  components: { AddConcatDialog },
  data() {
    return {
      dialogLoading: false,
      addressList: [],
      contactList: [], //客户下拉框
      engineerList: [], //工程师下拉框
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      isCarryEquList: [
        {
          id: 0,
          label: "否"
        },
        {
          id: 1,
          label: "是"
        }
      ],
      changeForm: {
        engineerrpid: [], //工程师
        isNeedVisit: 0, //是否需要上门
        isCarryEqu: 0, //是否携带设备
        contactId: "",
        contactName: "", //联系人
        contactPhone: "", //联系人电话
        insAddress: "",
        defaultCity: "",
        arrangedate: "",
        remark: "",
        corpContactName: "",
        corpContactPhone: "",
        implementationReason: ""
      },
      changeFormRules: {
        engineerrpid: [
          {
            required: true,
            message: "请选择工程师姓名",
            trigger: ["blur", "change"]
          }
        ],
        corpContactName: [
          {
            required: true,
            message: "请输入客户联系人",
            trigger: ["blur", "change"]
          }
        ],
        corpContactPhone: [
          {
            required: true,
            message: "请输入客户联系人电话",
            trigger: ["blur", "change"]
          }
        ],
        isNeedVisit: [
          {
            required: true,
            message: "请选择是否需要上门",
            trigger: ["blur", "change"]
          }
        ],
        // contactId: [
        //   {
        //     required: true,
        //     message: "请选择联系人",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        defaultCity: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: ["blur", "change"]
          }
        ],
        arrangedate: [
          {
            required: true,
            message: "请选择约定时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      addContactVisible: false //添加联系人
    };
  },
  props: {
    isChange: {
      type: Boolean,
      default: false
    },
    changeType: {
      type: String,
      default: ""
    },
    customerId: {
      type: [Number, String],
      default: ""
    },
    itemIdList: {
      type: Array,
      default: () => []
    },
    basicInfo: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: [Number, String],
      default: ""
    },
    itemList: {
      type: Array,
      default: () => []
    },
    hiddenTip: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {
    title() {
      let til = this.changeType == "assgin" ? "指派工程师" : "更换工程师";
      return til;
    },
    ignoreNum() {
      let num = 0;
      if (this.changeType == "assgin") {
        //指派,只能操作未分配，待指派状态的任务项
        num = this.itemList.filter(item => {
          return item.itemStatusCode != 1 && item.itemStatusCode != 2;
        }).length;
      } else {
        //更换，只能操作待接受，待作业
        num = this.itemList.filter(item => {
          return item.itemStatusCode != 3 && item.itemStatusCode != 4;
        }).length;
      }
      return num;
    }
  },
  watch: {
    isChange: {
      immediate: true,
      handler(val) {
        if (val) {
          let taskSource = this.basicInfo.taskSource;
          // let itemInfo =
          //   taskSource == 3 || taskSource == 5
          //     ? this.itemList[0]
          //     : this.basicInfo;
          this.changeForm.taskArrangedate = this.basicInfo.expactTimeStamp;
          this.initData();
          // if (taskSource != 3 && taskSource != 5) {
          //   this.getContactList();
          //   if (this.basicInfo.contactPhone) {
          //     this.changeForm.contactPhone = this.basicInfo.contactPhone;
          //     this.changeForm.contactName = this.basicInfo.contact;
          //   }
          // } else {
          //   this.changeForm.contactPhone = itemInfo.contactPhone;
          //   this.changeForm.contactName = itemInfo.contactName;
          // }
          if (
            (taskSource == 3 || taskSource == 5) &&
            this.basicInfo.sourceOrganization == 2
          ) {
            this.changeForm.contactPhone = this.basicInfo.contactPhone;
            this.changeForm.contactName = this.basicInfo.contact;
          } else {
            this.getContactList();
            if (this.basicInfo.contactPhone) {
              this.changeForm.contactPhone = this.basicInfo.contactPhone;
              this.changeForm.contactName = this.basicInfo.contact;
            }
          }
          //省
          if (this.basicInfo.insCountyName) {
            this.changeForm.provinceName = this.basicInfo.provinceName;
            this.changeForm.province = this.basicInfo.provinceId;
            //市
            this.changeForm.cityName = this.basicInfo.cityName;
            this.changeForm.city = this.basicInfo.cityId;
            //区
            this.changeForm.insCountyName = this.basicInfo.insCountyName;
            this.changeForm.insCounty = this.basicInfo.insCountyId;
            //街道
            this.changeForm.streetName = this.basicInfo.streetName;
            this.changeForm.street = this.basicInfo.streetId;
            //详细地址
            this.changeForm.insAddress = this.basicInfo.address2;
            //默认地址
            this.changeForm.defaultCity =
              this.basicInfo.provinceName +
              this.basicInfo.cityName +
              this.basicInfo.insCountyName +
              this.basicInfo.streetName;
            this.loadAddressData(this.changeForm.defaultCity);
          }
          this.changeForm.corpContactName = this.basicInfo.corpContactName;
          this.changeForm.corpContactPhone = this.basicInfo.corpContactPhone;
          //默认地址
          // if (this.basicInfo.insCountyName) {
          //   this.changeForm.defaultCity =
          //     itemInfo.provinceName +
          //     itemInfo.cityName +
          //     itemInfo.insCountyName +
          //     itemInfo.streetName;
          //   this.changeForm.province = itemInfo.provinceId; //省
          //   this.changeForm.provinceName = itemInfo.provinceName;
          //   this.changeForm.city = itemInfo.provinceId; //市
          //   this.changeForm.cityName = itemInfo.cityName;
          //   this.changeForm.insCounty = itemInfo.provinceId; //区
          //   this.changeForm.insCountyName = itemInfo.insCountyName;
          //   this.changeForm.street = itemInfo.provinceId; //街道
          //   this.changeForm.streetName = itemInfo.streetName; //街道
          //   this.changeForm.insAddress = itemInfo.address2;
          //   this.loadAddressData(this.changeForm.defaultCity);
          // }
        }
      }
    }
  },
  methods: {
    //获取下拉框数据
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList =
          (this.basicInfo.taskSource == 3 || this.basicInfo.taskSource == 5) &&
          !this.basicInfo.isGrayscaleWork
            ? [getEngineer({})]
            : [getEngineer({}), getContactList({ id: this.customerId })];
        let [engineerRes, contactRes] = await Promise.all(promiseList);
        //获取工程师下拉框
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
        //客户名称下拉框
        this.contactList = this.getFreezeResponse(contactRes, {
          path: "data"
        });
        //约定时间
        this.changeForm.arrangedate = this.basicInfo.expactTimeStamp;
        let contactInfo = this.contactList.find(item => {
          return item.contactName == this.basicInfo.contact;
        });
        if (contactInfo && contactInfo.contactName) {
          this.changeForm.contactId = contactInfo.contactId;
        }
        // if (contactInfo && contactInfo.phone1) {
        //   this.changeForm.contactPhone = contactInfo.phone1;
        // }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //联系人选择
    handleContact() {
      let concatInfo = this.contactList.find(item => {
        return item.contactId == this.changeForm.contactId;
      });
      if (concatInfo && concatInfo.contactName) {
        this.changeForm.contactName = concatInfo.contactName;
      }
      if (concatInfo && concatInfo.phone1) {
        this.changeForm.contactPhone = concatInfo.phone1;
      }
      if (concatInfo.addressList.length && concatInfo.addressList[0].areaName) {
        //省
        this.changeForm.provinceName = concatInfo.addressList[0].provinceName;
        this.changeForm.province = concatInfo.addressList[0].provinceId;
        //市
        this.changeForm.cityName = concatInfo.addressList[0].cityName;
        this.changeForm.city = concatInfo.addressList[0].cityId;
        //区
        this.changeForm.insCountyName = concatInfo.addressList[0].areaName;
        this.changeForm.insCounty = concatInfo.addressList[0].areaId;
        //街道
        this.changeForm.streetName = concatInfo.addressList[0].streetName;
        this.changeForm.street = concatInfo.addressList[0].streetId;
        //详细地址
        this.changeForm.insAddress = concatInfo.addressList[0].address;
        //默认地址
        this.changeForm.defaultCity =
          concatInfo.addressList[0].provinceName +
          concatInfo.addressList[0].cityName +
          concatInfo.addressList[0].areaName +
          concatInfo.addressList[0].streetName;
        this.loadAddressData(this.changeForm.defaultCity);
      } else {
        this.resetAddress();
      }
    },
    //重置地址
    resetAddress() {
      this.changeForm.provinceName = "";
      this.changeForm.province = "";
      //市
      this.changeForm.cityName = "";
      this.changeForm.city = "";
      //区
      this.changeForm.insCountyName = "";
      this.changeForm.insCounty = "";
      //街道
      this.changeForm.streetName = "";
      this.changeForm.street = "";
      //详细地址
      this.changeForm.insAddress = "";
      //默认地址
      this.changeForm.defaultCity = "";
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.changeForm.province = val.provinceCode;
      this.changeForm.provinceName = val.provinceName;
      this.changeForm.city = val.cityCode;
      this.changeForm.cityName = val.cityName;
      this.changeForm.insCounty = val.countyCode;
      this.changeForm.insCountyName = val.countyName;
      this.changeForm.streetId = val.streetCode;
      this.changeForm.streetName = val.streetName;
      this.changeForm.defaultCity = val.fullName;
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.changeForm.defaultCity = "";
        this.changeForm.province = "";
        this.changeForm.provinceName = "";
        this.changeForm.city = "";
        this.changeForm.cityName = "";
        this.changeForm.insCounty = "";
        this.changeForm.insCountyName = "";
        this.changeForm.streetId = "";
        this.changeForm.streetName = "";
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
        if (!this.addressList.length) {
          this.resetAddress();
        } else {
          if (this.changeForm.defaultCity && !this.changeForm.insCountyId) {
            let addressInfo = this.addressList.find(item => {
              return item.fullName == this.changeForm.defaultCity;
            });
            this.changeForm.province = addressInfo.provinceCode;
            this.changeForm.provinceName = addressInfo.provinceName;
            this.changeForm.city = addressInfo.cityCode;
            this.changeForm.cityName = addressInfo.cityName;
            this.changeForm.insCounty = addressInfo.countyCode;
            this.changeForm.insCountyName = addressInfo.countyName;
            this.changeForm.streetId = addressInfo.streetCode;
            this.changeForm.streetName = addressInfo.streetName;
          }
        }
      } catch (error) {
        printError(error);
      }
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.changeForm.validate(valid => {
        if (valid) {
          // if (!this.changeForm.insCountyName) {
          //   this.$message.warning("区县不能为空");
          //   return;
          // }
          var nameResult = this.engineerList.filter(v => {
            return this.changeForm.engineerrpid.indexOf(v.employeeId) !== -1; // 利用filter方法来遍历是否有相同的元素
          });
          //工程师名字
          this.changeForm.engineer = nameResult.map(
            ({ employeeId, employeeName, phoneNo }) => ({
              engineerRPid: employeeId,
              engineerName: employeeName,
              engineerPhone: phoneNo
            })
          );
          //联系人名字
          if (
            this.basicInfo.taskSource != 3 &&
            this.basicInfo.taskSource != 5
          ) {
            this.changeForm.contactName = this.filterSelect(
              this.contactList,
              "contactId",
              this.changeForm.contactId
            ).contactName;
          }

          if (this.changeType == "assgin") {
            this.assignEngineer();
          } else {
            this.changeEngineer();
          }
        }
      });
    },
    //更换工程师请求
    async changeEngineer() {
      try {
        this.dialogLoading = true;
        let res = await changeEngineer({
          ...this.changeForm,
          itemIdList: this.itemIdList,
          taskId: this.taskId
        });
        if (res.code == "OK") {
          this.$message.success("更换成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //指派工程师请求
    async assignEngineer() {
      try {
        this.dialogLoading = true;
        let res = await assignEngineer({
          ...this.changeForm,
          itemIdList: this.itemIdList,
          taskId: this.taskId
        });
        if (res.code == "OK") {
          this.$message.success("指派成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    addConcat() {
      this.addContactVisible = true;
    },
    //关闭添加联系人弹框
    closeDialog() {
      this.addContactVisible = false;
    },
    //获取联系人下拉框
    async getContactList() {
      try {
        this.loading = true;
        let res = await getContactList({ id: this.customerId });
        //客户名称下拉框
        this.contactList = this.getFreezeResponse(res, {
          path: "data"
        });

        let contactInfo = this.contactList.find(item => {
          return item.contactName == this.basicInfo.contact;
        });
        if (contactInfo && contactInfo.contactName) {
          this.changeForm.contactId = contactInfo.contactId;
        }
        //默认地址
        if (this.basicInfo.insCountyName) {
          this.changeForm.defaultCity =
            this.basicInfo.provinceName +
            this.basicInfo.cityName +
            this.basicInfo.insCountyName +
            this.basicInfo.streetName;
          this.changeForm.province = this.basicInfo.provinceId; //省
          this.changeForm.provinceName = this.basicInfo.provinceName;
          this.changeForm.city = this.basicInfo.cityId; //市
          this.changeForm.cityName = this.basicInfo.cityName;
          this.changeForm.insCounty = this.basicInfo.insCountyId; //区
          this.changeForm.insCountyName = this.basicInfo.insCountyName;
          this.changeForm.street = this.basicInfo.provinceId; //街道
          this.changeForm.streetName = this.basicInfo.streetName; //街道
          this.changeForm.insAddress = this.basicInfo.address2;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.changeForm = {
        engineerrpid: [], //工程师
        isCarryEqu: 0, //是否携带设备
        isNeedVisit: 1,
        contactId: "",
        contactName: "", //联系人
        contactPhone: "", //联系人电话
        insAddress: "",
        arrangedate: "",
        defaultCity: "",
        remark: "",
        province: "",
        provinceName: "",
        city: "",
        cityName: "",
        insCountyName: "",
        corpContactName: "",
        corpContactPhone: "",
        implementationReason: ""
      };
      this.$refs.changeForm.resetFields();
      this.$emit("handleClose", "isChange");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .dialog-title {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 35px;
    box-sizing: border-box;
    & > span {
      &:nth-child(1) {
        font-size: 14px;
        color: #646466;
      }
      &:nth-child(2) {
        color: #a3a7aa;
        font-size: 12px;
      }
    }
  }
  .el-input {
    width: 260px;
  }
  .el-select {
    width: 200px;
    .el-input {
      width: 200px;
    }
  }
}
</style>
